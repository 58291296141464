<template>
  <div class="goods-detail">
    <gl-title :title="$route.meta.title" backIcon></gl-title>
    <gl-card v-loading="loading">
      <el-form :model="formData" ref="detailForm" size="mini" label-width="120px">
        <el-form-item prop="itemName" label="商品名称">
          <span> {{formData.itemName}} </span>
        </el-form-item>
        <el-form-item prop="firstCategory" label="商品类目">
          <selectCascader :one.sync="formData.firstCategory" :two.sync="formData.secondCategory" :list="trees" :disabled="true"></selectCascader>
        </el-form-item>
        <el-form-item prop="productId" label="商品编号">
          <el-row>
            <el-col :span="8">
              <selected v-model="formData.productId" :productNumber.sync="formData.productNumber" :disabled="true"/>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item prop="itemName" label="是否预售">
          <span> {{formData.isPresale ? '是' : '否'}} </span>
        </el-form-item>
        <el-form-item prop="presaleDate" label="预售日期">
          <span> {{formData.presaleDate}} </span>
        </el-form-item>
        <el-form-item prop="isQuantity" label="是否限购">
          <span> {{formData.isQuantity ? '是' : '否'}} </span>
        </el-form-item>
        <el-form-item prop="quantity" label="限购数量">
          <span> {{formData.quantity}} </span>
        </el-form-item>
        <el-form-item prop="deliverDate" label="发货时间">
          <span> {{formData.deliverDate}} </span>
        </el-form-item>
        <el-form-item prop="isAfterSales" label="是否支持7天无理由">
          <span> {{formData.isAfterSales ? '是' : '否'}} </span>
        </el-form-item>
        <el-form-item prop="isBatch" label="是否批发">
          <span> {{formData.isBatch ? '是' : '否'}} </span>
        </el-form-item>
        <el-form-item v-if="formData.isBatch == 1" prop="batchQuantity" label="起批数量">
          <span> {{formData.batchQuantity }} </span>
        </el-form-item>
        <el-form-item prop="goodsItemPictureVOList" label="商品规格图片">
          <div class="preview-image">
            <div v-for="(item,index) of formData.goodsItemPictureVOList" :key="index" class="preview-image_item">
              <el-image
                style="width: 100px; height: 100px"
                :src="item.pictureUrl"
                fit="cover"
                :preview-src-list="[...formData.goodsItemPictureVOList.map(item => item.pictureUrl)]"
              />
            </div>
          </div>
        </el-form-item>
        <div>
          <div class="table-title">销售规格</div>
          <el-table :data="formData.goodsSkuVOList" style="width: 100%">
            <el-table-column prop="skuSize" label="大小" width="150"></el-table-column>
            <el-table-column prop="skuName" label="规格"></el-table-column>
            <el-table-column prop="retailPrice" label="价格(元)"></el-table-column>
            <el-table-column prop="inventory" label="库存(件)"></el-table-column>
            <el-table-column prop="costPrice" label="出厂价(元)"></el-table-column>
            <el-table-column v-if="formData.isPresale == 1" prop="presalePrice" label="预售价(元)"></el-table-column>
            <el-table-column v-if="formData.isBatch == 1" prop="batchPrice" label="批发价(元)"></el-table-column>
            <el-table-column prop="skuPicture" label="规格图">
              <template slot-scope="{row}">
                <el-image
                  style="width: 60px; height: 60px"
                  :src="row.skuPicture"
                  fit="cover"
                  :preview-src-list="[row.skuPicture]"
                />
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-form-item prop="itemDetail" label="商品详情描述" style="margin-top: 50px;" label-position="top">
          <gl-editor v-model="formData.itemDetail" :disabled="true"></gl-editor>
        </el-form-item>
      </el-form>
    </gl-card>
  </div>
</template>

<script>
import {getGoods, getTree} from '@/api/merchant/goods'
import selectCascader from './components/selectCascader.vue'
import selected from './components/selected.vue'
export default {
  components: {selectCascader,selected},
  data(){
    return{
      trees: [],
      formData: {},
      loading: false,
      itemId: '',
    }
  },
  mounted(){
    this.itemId = this.$route.query.itemId
    this.getGoods()
    this.getTrees()
  },
  methods: {
    async getGoods(){
      let params = {
        itemId: this.itemId,
      }
      this.loading = true
      const res = await getGoods(params)
      this.formData = res.data
      this.loading = false
    },
    async getTrees(){
      const res = await getTree()
      this.trees = res.data
    },
  },
}
</script>

<style lang="scss" scoped>
.goods-detail{
  .table-title{
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
  }
  .preview-image{
    display: flex;
    &_item{
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
}
</style>